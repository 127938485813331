import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Thumb = ({ node, backLocation, colSpan }) => {
  let patterns = ["diag", "hz", "round", "square", "wave"]

  let pattern = patterns.splice(~~(Math.random() * patterns.length), 1)[0]
  let path = 'url("../../img/pattern-' + pattern + '.png")'

  // last && patterns.push(last)
  // last = pattern
  let style = { backgroundImage: path }

  return (
    <article
      className={` col-xs-12 col-sm-${colSpan} bg-pattern `}
      style={style}
    >
      <Link
        to={node.fields.slug}
        title={node.frontmatter.title}
        state={{ backLocation }}
      >
        <div className="border animated">
          <Img
            fluid={node.frontmatter.thumb.childImageSharp.fluid}
            backgroundColor={true}
            alt={"Thumbnail zum Projekt " + node.frontmatter.title}
            title={node.frontmatter.title}
          />
        </div>
      </Link>
    </article>
  )
}

Thumb.propTypes = {
  node: PropTypes.object.isRequired,
  backLocation: PropTypes.string,
}

export default Thumb
